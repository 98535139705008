import React from "react";

function MobileNav({ open }) {
  return (
    // Mobile Nav
    <div
      className={`h-screen w-[80%] absolute bg-white px-8 py-20 space-y-10 top-[80px] duration-500 right-0 lg:hidden ease-in-out ${
        open ? " translate-x-0" : "translate-x-[999px]"
      }`}
    >
      <ul className="text-xl font-medium space-y-8 border-b-2 pb-10">
        <li>
          <a
            className="flex items-center space-x-2 font-semibold hover:text-main-color"
            href="https://donut-95196.firebaseapp.com/"
            target="_blank" rel="noreferrer"
          >
            Menu <span className="text-2xl">➜</span>
          </a>
        </li>
        <li></li>
      </ul>

      <div className="space-x-5">
      </div>
      <a
        className="flex items-center space-x-2 font-semibold hover:text-main-color"
        href="https://maps.app.goo.gl/SAwraHDYHvcu3Muq6"
        target="_blank"
        rel="noopener noreferrer"
      >
        <img className="h-6" src="/img/marker.svg" alt="Encuéntranos" />
        <span>Encuéntranos</span>
      </a>
    </div>
  );
}

export default MobileNav;
