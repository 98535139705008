import React from "react";

function BoxD() {
  const handleButtonClick = () => {
    window.open("https://www.instagram.com/indomablecafe/", "_blank");
  };

  return (
    <section className="bg-main-color bg-indomable-black max-w-[1240px] mx-auto  text-white flex flex-col lg:flex-row my-8">
      <div className="flex-1">
        <img className="h-auto" src="/img/Choose.png" alt="" />
      </div>
      <div className="flex flex-1 flex-col items-center space-y-10 text-center px-10 py-10 justify-center">
        <h2 className="uppercase text-4xl font-medium tracking-[0.2em] leading-snug">
          Nuestro café a tu casa
        </h2>
        <p className="text-lg leading-[1.9]">
          Envíanos mensaje, conoce las opciones, recoge y disfruta,
          ¡Así de fácil!
        </p>
        <button className="border border-white rounded-full py-2 px-4" onClick={handleButtonClick}>
          Ordena ahora
        </button>
      </div>
    </section>
  );
}

export default BoxD;
