import React, { useState } from "react";
import BoxA from "./Components/BoxA";
import BoxB from "./Components/BoxB";
import BoxC from "./Components/BoxC";
import BoxD from "./Components/BoxD";
import BoxE from "./Components/BoxE";
import Desc from "./Components/Desc";
import Footer from "./Components/Footer";
import MobileNav from "./Components/MobileNav";
import Nav from "./Components/Nav";
import Banner from "./Components/Banner";


function App() {
  const [open, setOpen] = useState(false);
  return (
    <div
      className={`App font-opensans relative overflow-x-hidden ${
        open && "max-h-screen overflow-y-hidden"
      }`}
    >
      <Nav open={open} setOpen={setOpen} />
      <Banner />
      <BoxA />
      <BoxB />
      <BoxC />
      <BoxD />
      <BoxE />
      <Desc />
      <Footer />
      <MobileNav open={open} />
    </div>
  );
}

export default App;
