import React from "react";

function Desc() {
  return (
    <div className="border-b-2 border-gray-300">
      <div className="mb-10 mt-16 flex justify-center px-5">
        <p className="max-w-3xl text-center leading-8 text-sm">
          Todos nuestros precios incluyen IVA, estamos sujetos a cambios sin previo aviso{" "}
          <a 
            href="https://indomablecafe.com/terms" 
            className="border-b border-black"
            target="_blank" 
            rel="noopener noreferrer"
          >
            indomablecafe.com/terms
          </a>
        </p>
      </div>
    </div>
  );
}

export default Desc;
