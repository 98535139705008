import React from "react";

function BoxA() {
  return (
    <section style={{ backgroundColor: '#262626' }} className="max-w-[1240px] mx-auto py-10 px-10 text-center text-white my-10">
      <h2 className="uppercase text-4xl font-bold tracking-[0.2em] mb-10 leading-[1.5] xl:text-5xl xl:leading-snug">
        Descubre nuestros productos auténticos de productores mexicanos
      </h2>
      <p className="text-xl">
        {" "}
        <a href="https://www.instagram.com/indomablecafe/" className="border-b">
        Síguenos en Instagram para estar al tanto de nuestras promociones
        </a>
      </p>
    </section>
  );
}

export default BoxA;
