import React from "react";

function BoxE() {
  return (
    <section className="bg-color-secondary bg-indomable-cream max-w-[1240px] mx-auto  text-black flex flex-col lg:flex-row-reverse my-8">
      <div className="flex-1">
        <img className="h-auto" src="/img/Mural.png" alt="" />
      </div>
      <div className="flex flex-1 flex-col items-center space-y-10 text-center px-10 py-10 justify-center ">
        <h2 className="uppercase text-4xl font-medium tracking-[0.2em] leading-snug">
          Creándo nuevos sabores y experiencias
        </h2>
        <p className="text-lg leading-[1.9]">
          Todas nuestras bebídas, llevan un pedazo de tradición y una mezcla de
          sabores tradiciones y legendarios con un toque indomable y audaz
        </p>
      </div>
    </section>
  );
}

export default BoxE;
