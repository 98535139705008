import React from "react";

function BoxB() {
  return (
    <section style={{ backgroundColor: '#7EA3CC' }} className="max-w-[1240px] mx-auto py-10 px-10 text-center text-white my-10">
      <div className="flex-2">
        <img className="h-auto" src="/img/CupCoffee.png" alt="" />
      </div>
      <div className="flex flex-1 flex-col items-center space-y-10 text-center px-10 py-10 justify-center ">
        <h2 className="uppercase text-4xl font-medium tracking-[0.2em] leading-snug">
          Nuestros productos, son estrellas
        </h2>
        <p className="text-lg leading-[1.9]">
          El frappe de pulque, una creación hecha 100% en Tlaxcala,
          con ingredientes naturales y de la mejor calidad.
        </p>
      </div>
    </section>
  );
}

export default BoxB;
