import React from "react";

function Banner() {
    return (
        <section
            style={{ backgroundColor: '#B3001B' }}
            className="max-w-[1240px] mx-auto py-10 px-10 text-center text-white my-10"
        >
            <h2
                className="uppercase text-4xl font-medium tracking-[0.2em] mb-10 leading-[1.5] xl:text-5xl xl:leading-snug"
            >
                ¿Quieres un café gratis?
            </h2>
            <p className="text-xl">
                {" "}
                <a 
                href="https://app.formbricks.com/s/clzu67vds0000p6yd0u5o8207" 
                target="_blank"
                className="border-b" rel="noreferrer">
                    ¡Nosotros invitamos!
                </a>
            </p>
        </section>
    )
}

export default Banner;
